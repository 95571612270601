'use client';

import { useEffect } from 'react';
import { useRouter, useParams } from 'next/navigation';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import HotelsPage from "./hotels/page";
import { routes } from "@/config/routes";

export default function RootPage() {
  const router = useRouter();
  const { lang } = useParams();
  const { isSuperAdmin, isLoading } = useCurrentUser();

  useEffect(() => {
    if (!isLoading) {
      if (isSuperAdmin) {
        router.push(`/${lang}${routes.superAdmin.dashboard}`);
      }
    }
  }, [isSuperAdmin, isLoading, router, lang]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isSuperAdmin ? null : <HotelsPage />;
}
